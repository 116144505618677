<template>
  <b-modal id="invoice-resume" size="md" hide-header hide-footer>
    <div class="modal-header">
      <div class="modal-title">
        {{
          isScheduledInvoice
            ? 'Confirmação de agendamento'
            : 'Confirmação dos dados'
        }}
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="modal__content">
      <h3 class="title__l">Resumo da nota</h3>

      <div class="nf-data">
        <p>
          Nota em nome de:
          <span class="prestador-name">
            {{ nfModalData?.prestador_razao_social }}
          </span>
        </p>
        <p>
          CNPJ: <span>{{ cnpjFormated(nfModalData?.prestador_cnpj) }}</span>
        </p>

        <br />

        <p>
          Para: <span>{{ nfModalData?.tomador?.nome }}</span>
        </p>
        <p>
          CPF: <span>{{ cpfFormated(nfModalData?.tomador?.cnpj_cpf) }}</span>
        </p>

        <br />

        <p>
          {{
            isScheduledInvoice ? 'Data agendada de emissão' : 'Data de emissão'
          }}:
          <span>
            {{ this.moment(nfModalData?.data_emissao).format('DD/MM/YYYY') }}
          </span>
        </p>
        <p>
          Valor da nota:
          <span>
            {{ parseNumberToMoney(nfModalData?.servico?.valor_servicos) }}
          </span>
        </p>
      </div>

      <div class="my-4 mt-5 text-right">
        <b-button
          variant="outline-danger"
          class="mr-3"
          @click="goBackInvoiceModal"
        >
          Voltar
        </b-button>

        <b-button variant="primary" @click="issueNF">Emitir NFS-e</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import api from '@/modules/nfe/api'
import { cpfFormated, cnpjFormated } from '@/utils/dataHelper.js'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  computed: {
    ...mapState('nfe', ['nfModalData', 'isScheduledInvoice'])
  },
  data() {
    return {
      clinic: getCurrentClinic()
    }
  },
  // beforeDestroy()
  methods: {
    moment,
    cpfFormated,
    cnpjFormated,
    parseNumberToMoney,
    goBackInvoiceModal() {
      this.$store.dispatch('nfe/updateIsScheduledInvoice', false)
      this.$bvModal.hide('invoice-resume')
      this.$bvModal.show('new-invoice-modal')
    },
    clearModal() {
      this.$store.dispatch('nfe/updateIsScheduledInvoice', false)
      this.$store.dispatch('nfe/updateProviderModalSelected', {})
      this.$store.dispatch('nfe/updateNfModalData', {})
    },
    closeModal() {
      this.clearModal()
      this.$bvModal.hide('invoice-resume')
    },
    async issueNF() {
      const isLoading = this.$loading.show()

      const currentDate = moment()
      const selectedDate = moment(this.nfModalData.data_emissao)
      const combinedDateTime = moment({
        year: selectedDate.year(),
        month: selectedDate.month(),
        day: selectedDate.date(),
        hour: currentDate.hour(),
        minute: currentDate.minute(),
        second: currentDate.second()
      })

      try {
        const params = {
          clinic_id: this.clinic.id,
          prestador_id: this.nfModalData.prestador_id,
          nome_tomador: this.nfModalData.tomador.nome,
          cpf_cnpj_tomador: this.nfModalData.tomador.cnpj_cpf
            .replace('.', '')
            .replace('.', '')
            .replace('/', '')
            .replace('-', ''),
          email_tomador: this.nfModalData.tomador.email,
          endereco_tomador: this.nfModalData.tomador.endereco,
          aliquota: this.nfModalData.servico.aliquota,
          data_emissao: combinedDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          discriminacao: this.nfModalData.servico.discriminacao,
          value: this.nfModalData.servico.valor_servicos,
          percentage_cofins: this.nfModalData.servico?.percentage_cofins,
          percentage_csll: this.nfModalData.servico?.percentage_csll,
          percentage_inss: this.nfModalData.servico?.percentage_inss,
          percentage_irrf: this.nfModalData.servico?.percentage_irrf,
          percentage_pis_pasep: this.nfModalData.servico?.percentage_pis_pasep,
          value_cofins: this.nfModalData.servico?.value_cofins,
          value_csll: this.nfModalData.servico?.value_csll,
          value_inss: this.nfModalData.servico?.value_inss,
          value_irrf: this.nfModalData.servico?.value_irrf,
          value_pis_pasep: this.nfModalData.servico?.value_pis_pasep,
          percentage_tax_burden: this.nfModalData.servico?.percentage_tax_burden,
          source_tax_burden: this.nfModalData.servico?.source_tax_burden,
          withhold_taxes: this.nfModalData.servico?.withhold_taxes,
          approximate_value_tax_burden: this.nfModalData.servico?.approximate_value_tax_burden,
          net_value: this.nfModalData.servico?.net_value
        }

        if (this.nfModalData.bill_id) {
          params.bill_id = this.nfModalData.bill_id
        }

        await api.createNF(params)
        const { data } = await api.getAllNFs(
          this.clinic.id,
          '',
          '',
          '',
          '',
          '',
          ''
        )

        this.$store.dispatch('nfe/updateNfTableData', data.data)
        this.$toast.success('Nota emitida com sucesso!')
      } catch (err) {
        console.error(err)
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss">
#invoice-resume {
  padding: 3rem;

  .modal-header {
    padding: 1rem 0;

    .modal-title {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #525c7a;
    }
  }

  .modal__content {
    .title__l {
      color: var(--neutral-700);
    }
  }

  .icon {
    width: 25px;
    fill: var(--neutral-500);
  }

  .nf-data {
    > p {
      font-size: 16px;
      font-family: 'Nunito Sans';
      color: var(--type-active);

      > span {
        color: var(--blue-700);
      }

      .prestador-name {
        font-weight: 700;
      }
    }
  }
}
</style>
